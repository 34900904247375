import { isDesktop } from './header-sub-funtions';

/* global debounce */
$( $ => {
  const body = $( 'body' );
  const notificationBar = $( '#notify-bar' );
  if ( !notificationBar.length ) {
    return false;
  }
  const cloneSpan = notificationBar.find( '.notify-bar__title span' ).clone();

  const tickerEnable = () => {
    const innerWidth = notificationBar.find( '.notify-bar__content' ).innerWidth() - 145;
    const textWidth = notificationBar.find( '.notify-bar__title' ).innerWidth();
    const textHeight = notificationBar.find( '.notify-bar__title' ).innerHeight();
    const buttonWidth = notificationBar.find( '.notify-bar__button-wrapper' ).innerWidth();

    if( !notificationBar.find( '.notify-bar__title' ).hasClass( 'ticker-enable' ) ){
      if( isDesktop() && ( ( textWidth + buttonWidth ) >= innerWidth ) ){
        cloneSpan.appendTo( notificationBar.find( '.notify-bar__title' ).addClass( 'ticker-enable' ) );
      }
      if( !isDesktop() && ( textHeight >= 45 ) ){
        cloneSpan.appendTo( notificationBar.find( '.notify-bar__title' ).addClass( 'ticker-enable' ) );
      }
    }
  };
  $( '.notify-bar .close-icon' ).on( 'click', () => {
    $( '.notify-bar' ).parents( '.bs-row' ).addClass( 'notify-bar-closed' );
    $.ajax( {
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: {
        action: 'mark_notify_bar_as_read',
      },
      success: () => {
        notificationBar.slideUp( 400, () => {
          body.removeClass( 'notify-bar-enable' );
        } );
      },
    } );
  } );

  $( window ).on( 'load resize', debounce( tickerEnable, 200 ) );
} );
