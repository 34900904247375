$( $ => {
  const tagLabel = $( '.facetwp-selection-label' );
  if( tagLabel.length ) {
    tagLabel.each( function ( ) {
      let str = $( this ).text();
      str = str.substring( 0, str.length - 1 );
      $( this ).text( str );
    } );
  }
} );
