import debounce from 'lodash/debounce';
require( 'slick-carousel-latest' );
( $ => {
  const tabSliderSection = $( '.bs-section--home-enterprises-tab-slider' );
  if ( !tabSliderSection.length ) {
    return false;
  }

  const first_slider_content = tabSliderSection.find( '.bs-tab-slider .bs-slider-content' ).first();
  const first_slider_tab = tabSliderSection.find( '.bs-tab-slider .bs-slider-tabs' ).first();
  first_slider_tab.slick();
  first_slider_content.slick();
  let clickEvent = false;

  const slickSliderAction = ( slickSlider, slickAction ) => {
    slickSlider.slick( slickAction );
  };

  const debounceHandler = debounce( () => {
    slickSliderAction( first_slider_content, 'slickPlay' );
    slickSliderAction( first_slider_tab, 'slickPlay' );
    clickEvent = false;
  }, 20000 );

  const slickPlayPause = () => {
    if ( first_slider_content.slick( 'slickGetOption', 'autoplay' ) ) {
      slickSliderAction( first_slider_content, 'slickPause' );
      slickSliderAction( first_slider_tab, 'slickPause' );
      debounceHandler();
    }
  };

  const slickSwipe = slider => {
    slider.on( 'swipe', function () {
      slickPlayPause();
    } );
  };

  const slickSliderAddRemoveClass = ( targetElement, index,className ) => {
    $( targetElement ).removeClass( className );
    $( targetElement[index] ).addClass( className );
  };

  // eslint-disable-next-line no-unused-vars
  first_slider_content.on( 'afterChange', ( event, slick, currentSlide, nextSlide ) => {
    // eslint-disable-next-line max-len
    if( $( event.target ).closest( '.bs-tab-slider' ).hasClass( 'bs-tab-slider--home-enterprises-tab-slider-one' ) && clickEvent == false ) {
      const inner_slider_content = $( event.target )
        .find( '.slick-slide.slick-active .bs-tab-slider .bs-slider-content' );
      const inner_slider_tab = $( event.target ).find( '.slick-slide.slick-active .bs-tab-slider .bs-slider-tabs' );
      inner_slider_tab.slick( 'slickGoTo', 0 );
      inner_slider_content.slick( 'slickGoTo', 0 );
    }
  } );

  const autoplayTime = first_slider_content.slick( 'slickGetOption', 'autoplaySpeed' );

  first_slider_tab.find( '> div' ).each( ( i, e ) => {
    $( e ).css( 'animation-duration', autoplayTime + 'ms' );
  } );

  first_slider_content.find( '> .slick-slide-wrapper > .bs-tab-slider' ).each( ( i, e ) => {
    $( e ).on( 'click', () => {
      slickPlayPause();
    } );
  } );

  $( '.bs-tab-slider--home-enterprises-tab-slider-two .bs-slider-tabs' ).each( ( i, innerTabs ) => {
    $( innerTabs ).find( '.slick-slide-wrapper' ).first().addClass( 'custom-active' );
    const tabsSlider = $( innerTabs ).slick();
    slickSwipe( tabsSlider );
  } );
  $( '.bs-tab-slider--home-enterprises-tab-slider-two .bs-slider-content' ).each( ( i, innerTabContent ) => {
    $( innerTabContent ).find( '> div' ).first().addClass( 'related-active' );
    const tabContentSlider = $( innerTabContent ).slick();
    slickSwipe( tabContentSlider );
    // eslint-disable-next-line no-unused-vars
    tabContentSlider.on( 'afterChange', ( e, s, cs, ns ) => {
      const relatedSlickTabItem = $( e.currentTarget )
        .closest( '.bs-tab-slider' )
        .find( '.bs-slider-tabs  .slick-slide .slick-slide-wrapper' );
      slickSliderAddRemoveClass( relatedSlickTabItem, cs, 'custom-active' );
    } );
  } );

  $( '.bs-tab-slider--home-enterprises-tab-slider-two .bs-slider-tabs .slick-slide-wrapper' ).on( 'click', event => {
    clickEvent = true;
    const index = $( event.currentTarget ).closest( '.slick-slide' ).data( 'slick-index' );

    const relatedSlickItem = $( event.currentTarget )
      .closest( '.bs-tab-slider' )
      .find( '.bs-slider-content .slick-slide .slick-slide-wrapper' )
      .removeClass( 'related-active' );
    $( relatedSlickItem[index] ).addClass( 'related-active' );

    $( event.currentTarget ).closest( '.slick-track' ).find( '.slick-slide-wrapper' ).removeClass( 'custom-active' );

    $( event.currentTarget ).addClass( 'custom-active' );
    
    slickPlayPause();
  } );

  $( first_slider_tab ).on( 'click', () => {
    clickEvent = false;
    slickPlayPause();
  } );  
} )( jQuery );
