/* eslint-env jquery */
/* global debounce */
require( 'slick-carousel-latest' );

class VerticalTabSlider {
  constructor( settings ) {
    this.settings = settings;
    this.sliderSection = $( '.bs-section--home-vertical-tab-slider' );
    this.nextSection = this.sliderSection.next();
    this.slider = this.sliderSection.find( '.bs-slider-content' );
    this.leftStickyTitles = $( '.bs-section--home-vertical-tab-slider .bs-slider-tabs .slick-slide-wrapper' );
    this.titleHeight = $( '.bs-section--home-vertical-tab-slider .bs-div--home-vertical-tab-slider-title' )
      .outerHeight( true );
    this.hH = $( 'header .header_nav__wrapper' ).height();
    this.sectionPaddingTop = parseInt( $( '.bs-section--home-vertical-tab-slider' ).css( 'padding-top' ) );
    this.wH = $( window ).height();
    this.prevYPos = 0;
  }

  //sticky section trigger html part building
  stickyTriggerHtmlInit () {
    const stickyItems = $( '.bs-section--home-vertical-tab-slider' ).find( '.bs-slider-tabs .slick-slide-wrapper' );
    const stickyContainer = $( '.bs-section--home-vertical-tab-slider .sticky-container' );
    let stickyContainerHeight = '';
    //sticky
    let stickyTriggerItems = '';
    const stickyItemsCount = stickyItems.length;
    const topIncrementVal = 80;

    //sticky container height
    stickyContainerHeight = stickyItemsCount * topIncrementVal + 'vh';
    stickyContainer.height( stickyContainerHeight );

    //set indicators top values
    let topIncrementPercentage = 0;
    stickyItems.each( ( i ) => {
      topIncrementPercentage = topIncrementVal * i;
      stickyTriggerItems =
        stickyTriggerItems +
        `
        <div 
          style="top:${topIncrementPercentage}vh" 
          id="steps${i}" 
          data-index="${i}" 
          class="sticky-trigger sticky-trigger-${i}">
        </div>`;
    } );

    let stickyTriggerHtml = '<div class="sticky-triggers">' + stickyTriggerItems + '</div>';
    $( '.bs-section--home-vertical-tab-slider .sticky-container' ).prepend( stickyTriggerHtml );
  }

  //wrap and make sticky section
  wrapAndMakeStickySection () {
    // Create new nested elements
    let $stickyWrapper = $( '<div>' ).addClass( 'sticky-wrapper' );
    let $stickyContainer = $( '<div>' ).addClass( 'sticky-container' );
    let $stickyCenter = $( '<div>' ).addClass( 'sticky-center' );

    // Use nested selectors to wrap the rows with the new structure
    $( '.bs-section--home-vertical-tab-slider > .container' )
      .wrapInner( $stickyCenter )
      .wrapInner( $stickyContainer )
      .wrapInner( $stickyWrapper );
    if( $( window ).width() < 768 || $( window ).height() < 900 ) {
      $( '.bs-section--home-vertical-tab-slider .sticky-center' ).css( {
        'top': `${ ( this.hH + this.sectionPaddingTop ) - this.titleHeight }px`
      } );
    }
  }

  //set max height of the slider item
  setSliderItemHeight () {
    let maxHeight = -1;
    $( '.bs-section--home-vertical-tab-slider .bs-div--home-vertical-tab-slider-item-xl' ).each( function () {
      const currHeight = $( this ).outerHeight();
      maxHeight = ( currHeight > maxHeight ) ? currHeight : maxHeight;
    } );
    $( '.bs-section--home-vertical-tab-slider .bs-div--home-vertical-tab-slider-item-xl' ).css( {
      'height': maxHeight+'px'
    } );
  }

  //content slider init
  contentSliderInit () {
    const slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      swipe: false,
      touchMove: false,
      fade: true,
      adaptiveHeight: false,
    };

    this.slider.attr( 'data-slick', JSON.stringify( slickSettings ) );

    this.slider.not( '.slick-initialized' ).slick()
      .on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
        $( e.target ).find( '.slick-slide' ).removeClass( 'prev-active' );
        $( e.target ).find( '.slick-slide' ).eq( nextSlide - 1 ).addClass( 'prev-active' );
      } );
    
  }

  //change left indicator index
  setActiveLeftTitles ( slideIndex ) {
    const stickyItems = $( '.bs-section--home-vertical-tab-slider' ).find( '.bs-slider-tabs .slick-slide-wrapper' );
    stickyItems.removeClass( 'active' );
    const activeItem = stickyItems.eq( slideIndex );
    activeItem.addClass( 'active' );
  }

  //set left indicator index
  setActiveLeftFirstTitle () {
    this.setActiveLeftTitles( 0 );
  }

  //change slider index
  setSliderIndex ( slideIndex ) {
    this.slider.slick( 'slickGoTo', slideIndex );
  }

  //trigger with intersection
  triggerElementWithSlider () {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0.5],
    };
    const sections = document.querySelectorAll( '.sticky-trigger' );
    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          const item = $( entry.target );
          const index = item.attr( 'data-index' );
          this.setSliderIndex( index );
          this.setActiveLeftTitles( index );
          $( entry.target ).parents( 'section' )
            .removeClass( 'active-slide-last-0' )
            .addClass( `active-slide-last-${sections.length - 1 - index}` );
        }
      } );
    }, options );
    sections.forEach( el => {
      io.observe( el );
    } );
  }

  scrollStickyItem ( index ) {
    let top = $( '.sticky-trigger' ).eq( index ).offset().top;
    top = ( index === 0 ) ? top + this.titleHeight : top;
    $( 'html, body' ).animate( { scrollTop: top - this.titleHeight - 20 }, 400 );
  }

  nextSectionPosition () {
    if ( this.sliderSection.length === 0 ) return;
    const currSection = this.sliderSection;
    const currSectionBox = currSection[0].getBoundingClientRect();
    const currSectionTop = currSectionBox.top;
    const currSectionBottom = currSectionBox.bottom;
    if( currSectionTop <= 0 && currSectionBottom > this.wH ) {
      currSection.addClass( 'bs-section-on-top' ).removeClass( 'bs-section-on-bottom' );
    } else if ( currSectionBottom <= this.wH ) {
      currSection.removeClass( 'bs-section-on-top' ).addClass( 'bs-section-on-bottom' );
    } else {
      currSection.removeClass( 'bs-section-on-top bs-section-on-bottom' );
    }
  }

  scrollDirection () {
    if ( this.sliderSection.length === 0 ) return;
    const currYPos = $( window ).scrollTop();
    if ( this.prevYPos > currYPos ) {
      this.sliderSection.addClass( 'scrolled-down' ).removeClass( 'scrolled-up' );
    } else {
      this.sliderSection.removeClass( 'scrolled-down' ).addClass( 'scrolled-up' );
    }
    this.prevYPos = currYPos;
  }

  wrapNextSections ( count ) {
    const sectionIndex = this.sliderSection.index();
    $( 'section' ).slice( sectionIndex+1,sectionIndex+1+count ).wrapAll( '<div class="bs-next-sections"></div>' );
  }

  debouncedHandlers () {
    if ( this.sliderSection.length === 0 ) return;
    this.setSliderItemHeight();
    this.contentSliderInit();
    this.triggerElementWithSlider();
    this.nextSectionPosition();
    this.scrollDirection();
  }	

  init () {
    if ( this.sliderSection.length === 0 ) return;
    //left sticky titles on click
    this.leftStickyTitles.on( 'click', ( e ) => {
      const clickedTitle = $( e.currentTarget );
      const currentIndex = clickedTitle.data( 'slick-index' );
      this.leftStickyTitles.removeClass( 'active' );
      clickedTitle.addClass( 'active' );
      this.scrollStickyItem( currentIndex );
    } );
    this.wrapAndMakeStickySection();
    this.stickyTriggerHtmlInit();
    this.setActiveLeftFirstTitle();
    this.wrapNextSections( 2 );
  }

  initLoad () {
    this.debouncedHandlers();
  }
}

const verticalTabSection = new VerticalTabSlider();
verticalTabSection.init();

$( window ).on( 'load', () => {
  verticalTabSection.initLoad();
} );
$( window ).on( 'scroll', () => {
  verticalTabSection.scrollDirection();
  verticalTabSection.nextSectionPosition();
} );

$( window ).on( 'orientationchange', debounce( () => { verticalTabSection.debouncedHandlers(); }, 500 ) );