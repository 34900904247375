$( ( $ ) => {
  const mediaQuery = window.matchMedia( '(min-width: 992px)' );
  const pricingTable = $( '.bs-section--pricing-features-table' );
  const pricingHeader = $( pricingTable ).find( '.table-header' );
  const pricingWrapper = $( pricingTable ).find( '.table-wrapper' );

  const observerOptions = {
    root: null,
    rootMargin: '-200px 0px 0px 0px',
    threshold: 0
  };
  

  const pricingStickyRemover = entries => {
    entries.forEach( entry => {
      if ( !entry.isIntersecting ) {
        pricingHeader.addClass( 'header-dissable' );
      } else {
        pricingHeader.removeClass( 'header-dissable' );
      }
    } );
  };

  const observer = new IntersectionObserver( pricingStickyRemover, observerOptions );
  if ( pricingWrapper.length && mediaQuery.matches ) {
    observer.observe( pricingWrapper[0] );
  }

} );