$( ( $ ) => {
  const wrapper = () => {
    const tootTips = $( '.bs-section--pricing-features-table .tool-tip'	);
    if ( tootTips.length ) {
      tootTips.each( ( i, item ) => {
        const wrapperContainer = $( item ).find( '.tool-tip-detail-wrapper__inner' );
        if( wrapperContainer.length < 1 ) {
          const div = document.createElement( 'div' );
          $( div ).attr( 'class', 'tool-tip-detail-wrapper__inner' );
          $( item ).find( '.tool-tip-detail-wrapper__inner' ).append( div );
          $( item ).find( '.tool-tip-title, .tool-tip-content, .tool-tip-feature-title, .tool-tip-feature-content' )
            .wrapAll( div );
        }
      } );
    }
  };

  wrapper();

} );