/* eslint-env jquery */
/* global debounce */
require( 'slick-carousel-latest' );

( function ( $, w ) {
  const slider = $( '.bs-section--why-how-kobiton-vtab .bs-slider-content' );
  const leftStickyTitles = $( '.bs-section--why-how-kobiton-vtab .bs-slider-tabs .slick-slide-wrapper' );
  const stickyItems = $( '.bs-section--why-how-kobiton-vtab' ).find( '.bs-slider-tabs .slick-slide-wrapper' );
  const stickyContainer = $( '.bs-section--why-how-kobiton-vtab .bs-div--sticky-block' );
  const stickyInnerContainer = $( '.bs-section--why-how-kobiton-vtab .bs-div--slider' );
  const slickSliderItems = $( '.bs-section--why-how-kobiton-vtab .bs-slider-content .slick-slide-wrapper' );
  const stickyDivInner = $( '.bs-section--why-how-kobiton-vtab .bs-div--sticky-block > .bs-div__inner' );
  const stickyInnerDivInner = $( '.bs-section--why-how-kobiton-vtab .bs-div--slider > .bs-div__inner' );
  const titleDescription = $( '.bs-section--why-how-kobiton-vtab .bs-div--title-description' );

  slickSliderItems.each( ( index, item ) => {
    if ( index !== 0 && $( item ).find( 'video' ).length > 0 ) {
      $( $( item ).find( 'video' ) ).trigger( 'pause' );
    }
  } );

  const teaserPlayPause = ( currentItem, action ) => {
    if ( $( currentItem ).find( 'video' ).length > 0 ) {
      $( currentItem ).find( 'video' ).trigger( action );
    }
  };

  // slick observe
  const slickObserver = ( slickSlider, slick ) => {
    const slickObserve = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        const currentSlide = slickSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slick.currentSlide );
        if ( entry.isIntersecting ) {
          if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
            currentSlide.find( 'video' ).get( 0 ).play();
          }
        } else {
          if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
            currentSlide.find( 'video' ).get( 0 ).pause();
          }
        }
      },
      { root: null, threshold: 0.5 }
    );
    slickObserve.observe( slickSlider[0] );
  };

  const contentSliderInit = () => {
    const slickSettings = {
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      swipe: false,
      touchMove: false,
      adaptiveHeight: false,
    };

    if ( window.innerWidth > 992 ) {
      slider.attr( 'data-slick', JSON.stringify( slickSettings ) );

      slider.on( 'init reinit', ( e, slick ) => {
        slickObserver( $( e.target ), slick );
        setStickySectionHeight();
      } );

      slider.on( 'beforeChange', ( event, slick, currentSlide, nextSlide ) => {
        teaserPlayPause( $( slickSliderItems[nextSlide] ), 'play' );
        teaserPlayPause( $( slickSliderItems[currentSlide] ), 'pause' );

        let slides = $( event.target ).find( '.slick-slide' );
        slides.removeClass( 'fade-in-up fade-in-down' );

        if ( nextSlide > currentSlide ) {
          slides.eq( nextSlide ).addClass( 'fade-in-up' );
        } else if ( nextSlide < currentSlide ) {
          slides.eq( nextSlide ).addClass( 'fade-in-down' );
        }
      } );

      slider.not( '.slick-initialized' ).slick();
    } else {
      slider.filter( '.slick-initialized' ).slick( 'unslick' );
    }
  };

  const stickyTriggerHTMLInit = () => {
    if ( stickyContainer.find( '.sticky-triggers' ).length ) {
      stickyContainer.find( '.sticky-triggers' ).remove();
    }

    if ( stickyInnerContainer.find( '.sticky-triggers' ).length ) {
      stickyInnerContainer.find( '.sticky-triggers' ).remove();
    }

    if ( window.innerWidth > 992 ) {
      const stickyItemsCount = stickyItems.length;
      const topIncrementVal = 70;

      //sticky container height
      const stickyContainerHeight = stickyItemsCount * topIncrementVal + 'vh';

      //set indicators top values
      let topIncrementPercentage = 0;
      let stickyTriggerItems = '';

      stickyItems.each( i => {
        topIncrementPercentage = i * topIncrementVal;
        stickyTriggerItems += `
              <div 
                style="top:${topIncrementPercentage}vh" 
                id="steps${i}" 
                data-index="${i}" 
                class="sticky-trigger sticky-trigger-${i}">
              </div>`;
      } );

      const stickyTriggerHtml = '<div class="sticky-triggers">' + stickyTriggerItems + '</div>';

      if ( $( window ).height() > 1440 ) {
        stickyContainer.height( stickyContainerHeight );
        stickyInnerContainer.height( 'auto' );
        stickyContainer.prepend( stickyTriggerHtml );
      } else {
        stickyInnerContainer.height( stickyContainerHeight );
        stickyContainer.height( 'auto' );
        stickyInnerContainer.prepend( stickyTriggerHtml );
      }
    } else {
      stickyContainer.height( 'auto' );
      stickyInnerContainer.height( 'auto' );
    }
  };

  //change left indicator index
  const setActiveLeftTitles = slideIndex => {
    stickyItems.removeClass( 'active' );
    const activeItem = stickyItems.eq( slideIndex );
    activeItem.addClass( 'active' );
  };

  //change slider index
  const setSliderIndex = slideIndex => {
    slider.slick( 'slickGoTo', slideIndex );
  };

  //trigger with intersection
  const triggerElementWithSlider = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [1],
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          const item = $( entry.target );
          const index = item.attr( 'data-index' );
          setSliderIndex( index );
          setActiveLeftTitles( index );
        }
      } );
    }, options );

    const sections = document.querySelectorAll( '.sticky-trigger' );
    sections.forEach( el => {
      io.observe( el );
    } );
  };

  const bodyAnimate = index => {
    let top = 0;
    top = $( '.bs-section--why-how-kobiton-vtab .sticky-trigger' ).eq( index ).offset().top;
    $( 'html, body' ).animate( { scrollTop: top - 150 }, 400 );
  };

  //set sticky section height
  const setStickySectionHeight = () => {
    let maxHeight = 0;
    slickSliderItems.each( ( index, element ) => {
      const slideHeight = $( element ).height();
      if ( slideHeight > maxHeight ) {
        maxHeight = slideHeight;
      }
    } );

    if ( $( window ).height() > 1440 ) {
      const titleDescHeight = titleDescription.outerHeight( true );
      const windowHeight = $( window ).height();
      const stickySectionHeight = maxHeight + titleDescHeight; 
      const topVal = ( windowHeight - stickySectionHeight )/2;

      stickyDivInner.height( stickySectionHeight );
      stickyInnerDivInner.height( 'auto' );

      //set section top value
      stickyDivInner.css( 'top', topVal );

    } else {
      
      stickyInnerDivInner.height( maxHeight );
      stickyDivInner.height( 'auto' );
      stickyInnerDivInner.height( 'auto' );

      //set section top value
      stickyDivInner.css( 'top', 'auto' );
    }
  };

  //left sticky titles on click active
  leftStickyTitles.on( 'click', e => {
    const clickedTitle = $( e.currentTarget );
    const slickIndex = clickedTitle.data( 'slick-index' );
    bodyAnimate( slickIndex );
  } );

  const debouncedHandlers = () => {
    stickyTriggerHTMLInit();
    contentSliderInit();
    triggerElementWithSlider();
    setStickySectionHeight();
  };

  $( document ).ready( function () {
    stickyTriggerHTMLInit();
    setActiveLeftTitles( 0 );
  } );

  $( w ).on( 'load', () => {
    contentSliderInit();
    triggerElementWithSlider();
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );
} )( jQuery, window );
