const { isDesktop } = require( './header-sub-funtions' );

/* eslint-env jquery */
$( () => {
  const parallaxContainer = $( '.bs-section--home-page-section-one' );
  if ( parallaxContainer.length ) {
    const secondSection = $( '.bs-section--home-page-section-two' );

    const addClassNew = element => {
      const heightWindow = $( window ).height();
      const parallaxElement = $( '.bs-section--home-page-section-one' )[0];
      const sectionHeight = $( parallaxElement ).height();

      const contentArea = $( '.bs-section--home-page-section-one .bs-column--home-hero-content' );
      const imageArea = $( '.bs-section--home-page-section-one .bs-column--home-hero-media' );
      const counterArea = $( '.bs-section--home-page-section-one .bs-row--home-counter' );
      const scrollValue = $( window ).scrollTop();

      if ( heightWindow > sectionHeight && isDesktop() ) {
        // eslint-disable-next-line
        $( parallaxElement ).css( {
          position: 'sticky',
          top: 0,
          height: '100vh',
          display: 'flex',
          'align-items': 'center',
        } );
      } else {
        $( parallaxElement ).css( { position: 'sticky', top: heightWindow - sectionHeight - 100, display: 'block' } );
      }

      $( contentArea ).css( {
        transform: `translate3d(0, -${scrollValue / 15}px, 0)`,
      } );

      $( imageArea ).css( {
        transform: `translate3d(0, -${scrollValue / 10}px, 0)`,
      } );

      $( counterArea ).css( {
        transform: `translate3d(0, -${scrollValue / 20}px, 0)`,
      } );

      const newName = element[0].getBoundingClientRect();

      if ( newName.top < heightWindow / ( heightWindow > sectionHeight ? 1.1 : 1.11 ) ) {
        element.addClass( 'scale-down-class' );
      } else {
        element.removeClass( 'scale-down-class' );
      }
    };
    $( window ).on( 'scroll load resize', () => {
      addClassNew( secondSection );
    } );
  }
} );
