( function () {
  const footerCTA = document.querySelectorAll( '.bs-section--bottom-cta' );
  let previousY = 0;

  function footerCtaAnimation( entries ) {
    entries.forEach( ( entry ) => {
      const currentY = entry.boundingClientRect.y;
      const isIntersecting = entry.isIntersecting;
      const cta = entry.target.querySelector( '.bs-column' );

      if ( currentY < previousY ) {
        cta.classList.add( 'active' );
      } else if ( currentY > previousY && !isIntersecting ) {
        cta.classList.remove( 'active' );
      }

      previousY = currentY;
    } );
  }

  function createObserver( target, callback ) {
    const options = {
      root: null,
      threshold: 0.4,
    };
    const observer = new IntersectionObserver( callback, options );
    observer.observe( target );
  }

  if ( footerCTA.length > 0 ) {
    footerCTA.forEach( ( cta ) => {
      createObserver( cta, footerCtaAnimation );
    } );
  }
} )();