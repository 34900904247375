import { teaserPlayPause } from '../custom/general-funtions';
( ( $, fBox ) => {
  const heroTeaser = $( '.bs-section--home-hero-banner .media-elements .preview-video-wrapper' );
  if ( heroTeaser.length ) {
    heroTeaser.next( 'a' ).on( 'click', () => {
      teaserPlayPause( heroTeaser, 'pause' );
    } );
    fBox.bind( '.bs-section--home-hero-banner .media-elements a[data-fancybox]', {
      on: {
        // eslint-disable-next-line no-unused-vars
        shouldClose: fancybox => {
          teaserPlayPause( heroTeaser, 'play' );
        },
      },
    } );
  }
  // eslint-disable-next-line no-undef
} )( $, FancyappsUi );
