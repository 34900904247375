$( () => {
  const mediaQueryOne = window.matchMedia( '(min-width: 1921px)' );
  const mediaQueryTwo = window.matchMedia( '(max-width: 620px)' );
  const logoMove = ( items, scrollSpace ) => {
    $( window ).on( 'load scroll resize', () => {
      const $parallaxElement = items;
      $parallaxElement.each( ( i, element ) => {
        const $currentElement = $( element ),
          windowTop = $( window ).scrollTop(),
          elementTop = $currentElement.offset().top,
          elementHeight = $currentElement.height(),
          viewPortHeight = window.innerHeight - elementHeight,
          scrolled = windowTop - elementTop + viewPortHeight;

        if ( i === 0 ) {
          $currentElement.css( {
            transform:
              'translate3d(0, ' + scrolled * scrollSpace + 'px, 0)',
          } );
        }

        if ( i === 1 ) {
          $currentElement.css( {
            transform:
              'translate3d(0, ' + ( -scrolled * scrollSpace ) + 'px, 0)',
          } );
        }
      } );
    } );
  };
  
  if ( mediaQueryOne.matches ) {
    logoMove( $( '.bs-div--compliance-logo-animate-column' ), 0.08 );
  } else if ( mediaQueryTwo.matches ) {
    logoMove( $( '.bs-div--compliance-logo-animate-column' ), 0.1 );
  } else {
    logoMove( $( '.bs-div--compliance-logo-animate-column' ), 0.2 );
  }
  
} );
