$( $ => {
  // eslint-disable-next-line max-len
  const featuredSection = $( '.bs-section--newsroom-featured-news, .bs-section--resources-featured-post, .bs-section--blog-featured-posts, .bs-section--events-featured' );
  if ( !$( featuredSection ).has( '.bs-post' ).length ) {
    $( featuredSection ).addClass( 'no-featured-posts-in-the-page' );
    $( featuredSection ).next().addClass( 'no-featured-posts-next-section' );
  } else {
    $( featuredSection ).addClass( 'available-featured-posts-in-the-page' );
  }
  // eslint-enable-next-line max-len
} );
