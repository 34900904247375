$( ( $ ) => {
  const appendTitles = () => {
    const columnEvents = $( '.bs-column--events-title' );
    if ( columnEvents.length === 0 ) return;
    const parentRow = columnEvents.parents( '.bs-row' );
    parentRow.each( ( index, element ) => {
      element = $( element );
      const titleColumn = element.find( '.bs-column--events-title' ).clone();
      const postParent = element.find( '.bs-posts__list' );
      const postParentPast = element.find( '.bs-posts__normal-row' );
      if (
        postParent.length &&
        !postParent.children( '.bs-column--events-title' ).length
      ) {
        postParent.prepend( titleColumn );
      }
      if (
        postParentPast.length &&
        !postParentPast.children( '.bs-column--events-title' ).length
      ) {
        postParentPast.prepend( titleColumn );
      }
    } );

  };

  const appendDateAndType = () => {
    const eventList = $( '.bs-section--events-upcoming-events .bs-row--common-post-list .bs-posts__list' );
    const pastEventList =
      $( '.bs-section--events-past-events .bs-posts__normal .bs-posts__normal-row' );
    if ( eventList.length ) {
      eventList.find( '.bs-posts__column' ).each( ( index, element ) => {
        $( element ).find( '.bs-post__event-type, .bs-post__date' ).wrapAll(
          '<div class=bs-post__type-date-wrapper></div>' );
      } );
    }
    if ( pastEventList.length ) {
      pastEventList.find( '.bs-post' ).each( ( index, element ) => {
        $( element ).find( '.bs-post-taxonomy_event-type, .bs-post-event_date' ).wrapAll(
          '<div class=bs-post__type-date-wrapper></div>' );
      } );
    }
  };

  appendTitles();
  appendDateAndType();

  $( document ).on( 'facetwp-loaded', () => {
    appendTitles();
    appendDateAndType();
  } );

} );
