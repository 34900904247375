$( function() {
  const timelineInner = $( '.timeline-inner' ); // Fetch .timeline-inner only once
	
  class SliderScroll {
    constructor( element ) {
      this.slider = element;
      this.isDown = false;
      this.startX = 0;
      this.scrollLeft = 0;
			
      // Mouse events
      this.slider.addEventListener( 'mousedown', this.handleMouseDown.bind( this ) );
      this.slider.addEventListener( 'mouseleave', this.handleMouseLeave.bind( this ) );
      this.slider.addEventListener( 'mouseup', this.handleMouseUp.bind( this ) );
      this.slider.addEventListener( 'mousemove', this.handleMouseMove.bind( this ) );
			
      // Touch events
      this.slider.addEventListener( 'touchstart', this.handleTouchStart.bind( this ) );
      this.slider.addEventListener( 'touchmove', this.handleTouchMove.bind( this ) );
      this.slider.addEventListener( 'touchend', this.handleTouchEnd.bind( this ) );
    }
		
    handleMouseDown( e ) {
      this.isDown = true;
      this.slider.classList.add( 'active' );
      this.startX = e.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    }
		
    handleMouseLeave() {
      this.isDown = false;
      this.slider.classList.remove( 'active' );
    }
		
    handleMouseUp() {
      this.isDown = false;
      this.slider.classList.remove( 'active' );
    }
		
    handleMouseMove( e ) {
      if ( !this.isDown ) return;
      e.preventDefault();
      const x = e.pageX - this.slider.offsetLeft;
      const walk = ( x - this.startX ) * 1;
      this.slider.scrollLeft = this.scrollLeft - walk;
    }
		
    handleTouchStart( e ) {
      const touch = e.touches[0];
      this.isDown = true;
      this.slider.classList.add( 'active' );
      this.startX = touch.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    }
		
    handleTouchMove( e ) {
      if ( !this.isDown ) return;
      e.preventDefault();
      const touch = e.touches[0];
      const x = touch.pageX - this.slider.offsetLeft;
      const walk = ( x - this.startX ) * 1;
      this.slider.scrollLeft = this.scrollLeft - walk;
    }
		
    handleTouchEnd() {
      this.isDown = false;
      this.slider.classList.remove( 'active' );
    }
  }
	
  const sliders = document.querySelectorAll( '.timeline-inner' );
  sliders.forEach( ( slider ) => new SliderScroll( slider ) );
	
  // move half visible items
  const boxes = $( '.timeline__content' );
	
  let delayTimer;
	
  boxes.on( 'mouseenter', function() {
    const element = this;
    $( element ).addClass( 'hovered' );
		
    clearTimeout( delayTimer );
    delayTimer = setTimeout( function() {
      const result = isElementCropped( element );
			
      if ( result.cropped ) {
        if ( result.side === 'left' ) {
          timelineInner.animate( {
            scrollLeft: timelineInner.scrollLeft() - result.pixels
          }, 300 );
        } else if ( result.side === 'right' ) {
          timelineInner.animate( {
            scrollLeft: timelineInner.scrollLeft() + result.pixels + 20
          }, 300 );
        }
      }
    }, 300 );
  } );
	
  boxes.on( 'mouseleave', function() {
    clearTimeout( delayTimer );
    $( this ).removeClass( 'hovered' );
  } );
	
  function isElementCropped( element ) {
    const rect = element.getBoundingClientRect();
    const parentRect = timelineInner[0].getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
		
    if ( rect.right > viewportWidth ) {
      const croppedPixels = rect.right - viewportWidth;
      return {
        cropped: true,
        side: 'right',
        pixels: croppedPixels,
      };
    } else if ( rect.left < parentRect.left ) {
      const croppedPixels = Math.abs( parentRect.left - rect.left );
      return {
        cropped: true,
        side: 'left',
        pixels: croppedPixels,
      };
    }
		
    return {
      cropped: false,
      side: '',
      pixels: 0,
    };
  }
} );
