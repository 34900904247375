$( ( $ ) => {
  const tableHeaders = $( '.bs-section--blog-inner-content .bs-div--blog-inner-table' );
  const observerOptions = {
    root: null,
    rootMargin: '0px 0px -55% 0px',
    threshold: 0
  };

  const setPricingHeaderSticky = entries => {
    entries.forEach( entry => {
      const targetHeader = $( entry.target ).find( 'table thead' );
      if ( entry.isIntersecting || entry.boundingClientRect.top < 0 ) {
        targetHeader.addClass( 'thead-sticky' );
      } else {
        targetHeader.removeClass( 'thead-sticky' );
      }
    } );
  };

  const observer = new IntersectionObserver( setPricingHeaderSticky, observerOptions );
  function checkWindowWidthAndObserve() {
    let wW = $( window ).width();
    if ( wW > 1199 ) {
      tableHeaders.each( function() {
        observer.observe( this );
      } );
    }
  }

  checkWindowWidthAndObserve();

  $( window ).on( 'load resize', () => {
    observer.disconnect();
    checkWindowWidthAndObserve();
  } );
} );
