// NOSONAR: Cognitive complexity rule ignored for this function
( $ => {

  const $commonLogoSliders = $( '.bs-section--customers-logo-slider' );

  let maxWidth = 0;
	
  if ( $commonLogoSliders.length > 0 ) {

    const logoPlayHandler = ( index, slider, direction, speed ) => {
      let cloneUl = $( slider ).find( '.clients-list' );
      const wrapperWidth = cloneUl.width();
      const animationSpeed = speed;
      const animationDiretion = direction;
      let keyframes = '';
      
      //get max width of slider wrappers to maintain equal speed
      if( maxWidth <= wrapperWidth ){
        maxWidth = wrapperWidth;
      }
      const calculatedSpeed = ( maxWidth*animationSpeed )/ 1000 ;

      $( slider ).children().append( cloneUl.clone() );
      $( slider ).find( 'ul' ).first().addClass( 'marquee' );
      $( slider ).find( 'ul' ).last().addClass( 'marquee-cloned' );

      if( animationDiretion == 'left' ) {
        //define direction using keyframes
        keyframes = `
            @keyframes marqueeKeyFrameLeft${index} {
              0% { left: 0; }
              100% { left: -${wrapperWidth}px; }
            }
            @keyframes marqueeKeyFrameLeftCloned${index} {
              0% { left: ${wrapperWidth}px; }
              100% { left: 0; }
            }
          `;
        // Apply the animation to the element using the CSS 'animation' property
        $( slider ).find( '.marquee' )
          .css( 'animation', `marqueeKeyFrameLeft${index} ${calculatedSpeed}ms linear infinite` );
        $( slider ).find( '.marquee-cloned' )
          .css( 'animation', `marqueeKeyFrameLeftCloned${index} ${calculatedSpeed}ms linear infinite` );

      } else {
        keyframes = `
          @keyframes marqueeKeyFrameRight${index} {
            0% { left: 0; }
            100% { left: ${wrapperWidth}px; }
          }
          @keyframes marqueeKeyFrameRightCloned${index} {
            0% { left: -${wrapperWidth}px; }
            100% { left: 0; }
          }
        `;
        // Apply the animation to the element using the CSS 'animation' property
        $( slider ).find( '.marquee' )
          .css( 'animation', `marqueeKeyFrameRight${index} ${calculatedSpeed}ms linear infinite` );
        $( slider ).find( '.marquee-cloned' )
          .css( 'animation', `marqueeKeyFrameRightCloned${index} ${calculatedSpeed}ms linear infinite` );
      }
  
      const styleSheet = $( '<style></style>' );
      styleSheet.text( keyframes );
      // Append the style sheet to the head of the document
      $( 'head' ).append( styleSheet );

    };
      //select sliders in a page
    window.addEventListener( 'load', function() {
      $commonLogoSliders.find( '.clients-slider-wrapper' ).map( ( i, slider ) => {
        let logoWrapper = $( slider ).find( '.clients-list' );
        $( logoWrapper ).append( $( logoWrapper ).find( 'li' ).clone() );
        setTimeout( function(){
          logoPlayHandler( i, slider, $( slider ).attr( 'data-direction' ), $( slider ).attr( 'data-speed' ) );
          $( '.clients-wrap' ).css( 'visibility', 'visible' );
        },200 );
      } );
    } );
  }
} )( jQuery );
