$( () => {
  function lazyLoadImages() {
    const mediaElements = document.querySelectorAll( '.media-elements.bs-media-element--lazyloads' );

    const observer = new IntersectionObserver( ( entries, observer ) => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          const mediaElement = entry.target;
          const image = entry.target.querySelector( 'img' );
          const source = entry.target.querySelector( 'source' );
          const src = source.getAttribute( 'srcset' );
          image.setAttribute( 'src', src );
          source.remove();
          observer.unobserve( mediaElement );
        }
      } );
    } );

    mediaElements.forEach( image => {
      observer.observe( image );
    } );
  }

  lazyLoadImages();
} );
