( $ => {
  const topMenu = $( '#mega-menu-top-menu' );
  const mainMenu = $( '#mega-menu-main-menu' );
  const topMenuClone = topMenu.clone();
  const primaryLink = mainMenu.find( '.mega-primary-link' );
  const primaryLinkClone = primaryLink.clone();
  primaryLinkClone.addClass( 'mobile-top-menu-item' );

  // Add mega menu classed to top menu
  topMenuClone.find( '.mega-menu-item' ).addClass( 'mobile-top-menu-item' );
  const addIndicator = topMenuClone.find( '.menu-item-has-children > a' );
  topMenuClone.find( '.menu-item-has-children > a' )
    .html( `<span class="mega-indicator" data-has-click-event="true"></span>${addIndicator.html()}` );

  const mobileTopMenu = topMenuClone.html();
  mainMenu.append( mobileTopMenu + primaryLinkClone.prop( 'outerHTML' ) );
  mainMenu.on( 'click', '.menu-item-has-children > a', function() {
    $( this ).parent().toggleClass( 'mega-toggle-on' );
  } );

  $( '.mobile-top-menu-item' ).hide();

  function modifyMobileMenu() {
    if ( window.innerWidth < 992 ) {
      primaryLink.hide();
      $( '.mobile-top-menu-item' ).show();
    } else {
      primaryLink.show();
      $( '.mobile-top-menu-item' ).hide();
    }
  }
  // Add active class to wp membership login menu item
  function partnerLoginActiveMenu() {
    let current = window.location.href;
    $( '.mega-menu-item-has-children ul li.wp-membership-login .swpm_mini_login_wrapper a' ).each( function(){
      const $this = $( this );
      // if the current path is like this link, make sub-menu and menu-item active
      if( $this.attr( 'href' ) == current ){
        $this.parent().closest( '.mega-menu-item-has-children' ).addClass( 'mega-current-menu-parent' );
        $this.parent().closest( '.wp-membership-login' ).addClass( 'mega-current-menu-item' );
        $this.addClass( 'mega-current-menu-link' );
      }
    } );
  }
  modifyMobileMenu();
  partnerLoginActiveMenu();
  $( window ).on( 'resize orientationchange', modifyMobileMenu );

} )( jQuery );
