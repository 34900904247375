$( () => {
  window.addEventListener( 'message', event => {
    try {
      const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
      if ( event.origin && allowedOrigins.includes( event.origin ) ) {
        if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
          
          const $el = $(
            'input[type="text"].hs-input, ' +
            'input[type="password"].hs-input, ' +
            'input[type="email"].hs-input, ' +
            'input[type="tel"].hs-input, ' +
            'textarea.hs-input'
          );

          const initialFieldValues = new Map();

          $el.each( function () {
            const fieldName = $( this ).attr( 'name' );
            initialFieldValues.set( fieldName, '' );
          } );

          setInterval( () => {
            $el.each( function () {
              const fieldName = $( this ).attr( 'name' );
              const currentValue = $( this ).val();

              if ( currentValue !== initialFieldValues.get( fieldName ) ) {
                $( this ).parent().parent().addClass( 'active' );
                initialFieldValues.set( fieldName, currentValue );
              }
            } );
          }, 300 );

          $( document ).on( 'load change input focus', '.hs-input' , ( e ) => {
            if ( ! $( e.currentTarget ).val() || $( e.currentTarget ).val() !== '' ) {
              $( e.currentTarget ).parent().parent().addClass( 'active' );
            } else {
              $( e.currentTarget ).parent().parent().removeClass( 'active' );
            }
          } ).on( 'focusout', '.hs-input' , ( e ) => {
            if ( ! $( e.currentTarget ).val() ) {
              $( e.currentTarget ).parent().parent().removeClass( 'active' );
            } else {
              $( e.currentTarget ).parent().parent().addClass( 'active' );
            }
          } );

          const hsSubmit = document.querySelector( '.bs-div--form-wrapper .hs-submit' );
          const input = hsSubmit.querySelector( 'input[type="submit"]' );
          hsSubmit.addEventListener( 'click', () => {
            input.click();
          } );
        }
      } else {
        return false;
      }
    } catch ( e ) {
      console.error( e );
    }
  } );
} );
