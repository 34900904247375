$( $ => {
  const addRemainingWrapper = item => {
    const remainingItemsContainer = $( item ).find( '.remaining-items' );
    if ( remainingItemsContainer.length < 1 ) {
      const div = document.createElement( 'span' );
      $( div ).attr( 'class', 'remaining-items' );
      $( item ).find( '.bs-post__tags' ).append( div );
    }
  };

  const getInnerWidth = ( element ) => {
    const innerWidth = $( element ).innerWidth() - 17;
    return innerWidth;
  };

  const getElementWidths = ( containerClass, tagClass ) => {
    const container = $( containerClass ), 
      elements = $( container ).find( tagClass ), 
      elementWidths = [],
      marginSize = 8;
    for ( const element of elements ) {
      const elementWidth = element.offsetWidth + marginSize;
      elementWidths.push( elementWidth );
    }
    return elementWidths;
  };

  const addItemsUntilValue = ( array, tagsContainerWidth, item ) => {
    let sum = 0;
    let count = 0;
    const boxes = $( item ).find( '.tag' );

    for ( let i = 0; i < array.length; i++ ) {
      sum += array[i];
      if ( sum >= tagsContainerWidth ) {
        boxes.eq( [i] ).addClass( 'tag--hide' );
      } else {
        count++;
      }
    }

    return {
      sum: sum,
      count: count,
    };
  };

  const getClassOccurrences = item => {
    const elements = $( item ).find( '.tag' );
    const occurrences = elements.length;
    return occurrences;
  };

  const tagsArrange = ( selector ) => {
    const postBlock = $( selector );
    if ( postBlock ) {
      postBlock.each( ( i, item ) => {
        const tagsContainer = $( item ).find( '.bs-post__tags' );
        $( tagsContainer ).addClass( 'bs-post__tags--loaded' );
        const tag = $( item ).find( '.tag' );
        const elementWidths = getElementWidths( tagsContainer, tag );
        const elementsArray = elementWidths;
        const tagsContainerWidth = getInnerWidth( tagsContainer );
        const result = addItemsUntilValue( elementsArray, tagsContainerWidth, item );
        const occurrences = getClassOccurrences( item );
        const tagsmore = occurrences - result.count;
        if ( tagsmore > 0 ) {
          addRemainingWrapper( item );
          const remainingItemsContainer = $( item ).find( '.remaining-items' );
          if ( remainingItemsContainer ) {
            $( remainingItemsContainer ).html( '+' + tagsmore );
          }
        } else {
          $( item ).find( '.bs-post__tags' ).addClass( 'no-remaining-tags' );
        }
        if ( result.count < 2 ) {
          $( item ).find( '.bs-post__tags .tag' ).first().addClass( 'tag--long' );
        }
      } );
    }
  };

  $( document ).on( 'facetwp-loaded', () => {
    tagsArrange( '.bs-post .bs-post__details' );
    tagsArrange( '.bs-post-integrations .bs-post__details' );
  } );

  tagsArrange( '.bs-post .bs-post__details' );
  tagsArrange( '.bs-post-integrations .bs-post__details' );

} );
