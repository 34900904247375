$( () => {
  $( '.bs-post .bs-post__category' ).each( ( index, element ) => {
    let resourceTypes = $( element ).text().trim().split( '|' );
    resourceTypes = resourceTypes.map( element => {
      return element.trim();
    } );
    if ( resourceTypes.includes( 'Video' ) ) {
      $( element ).closest( '.bs-post' ).addClass( 'video' );
    }
  } );
} );