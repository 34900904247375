const paginationURLCheck = () => {
  let regex = /\/page\/\d+\//;
  if ( regex.test( window.location.href ) ) {
    const pastEventListTop = $( '.bs-post-block--event-past-events' );
    if ( pastEventListTop.length ) {
      const speed = 500;
      $( 'header' ).removeClass( 'scrolled-up' );
      const adminBarHeight =
        $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
      const sectionTop = $( pastEventListTop ).offset().top;
      const headerHeight =
        $( 'header' ).height() + adminBarHeight;
      let screenSizeAdjustment = 0;
      const windowWidth = $( window ).width();
      if ( windowWidth > 2080 ) {
        screenSizeAdjustment = 350;
      } else if ( windowWidth > 1780 ) {
        screenSizeAdjustment = 250;
      } else if ( windowWidth > 1730 ) {
        screenSizeAdjustment = 200;
      } else if ( windowWidth > 1600 ) {
        screenSizeAdjustment = 150;
      } else if ( windowWidth > 1400 ) {
        screenSizeAdjustment = 100;
      } else if ( windowWidth > 1024 ) {
        screenSizeAdjustment = 50;
      } else if ( windowWidth > 767 ) {
        screenSizeAdjustment = -50;
      }
      $( 'html, body' ).animate(
        { scrollTop: sectionTop - ( headerHeight + screenSizeAdjustment )},
        speed
      );
    }
  }
};

$( () => {
  $( window ).on( 'load', ()=> {
    paginationURLCheck();
  } );
} );