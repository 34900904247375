import debounce from 'lodash/debounce';
require( 'slick-carousel-latest' );

$( $ => {

  const mobileSliderDOM = () => {
    const accordionSliders = $( '.bs-advance-accordion--common-accordion-slider' );
    if ( accordionSliders.length > 0 ) {
      accordionSliders.each( ( i, el ) => {
        const cards = $( el ).find( '.card' );

        cards.each( ( i, card ) => {
          const cardHeader = $( card ).find( '.card-header' );
          const cardContent = $( card ).find( '.collapse' );

          $( card ).addClass( 'active' );
          cardHeader.removeClass( 'accordion__block__btn' );
          cardContent.addClass( 'card__content' ).removeClass( 'collapse' );

          const headerClone = cardHeader.clone();
          headerClone.find( 'h3' ).addClass( 'temp-header' );
          cardContent.prepend( headerClone.html() );
          cardHeader.hide();

          // Prevent accordion toggle
          cardHeader.on( 'click', ( e ) => {
            e.preventDefault();
            return false;
          } );
        } );

        $( el ).find( '.bs-advance-accordion__right-container > div' ).slick( {
          responsive: [
            {
              breakpoint: 9999,
              settings: 'unslick'
            }, {
              breakpoint: 990,
              settings: {
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
              }
            }
          ],
        } );
      } );
    }
  };

  const desktopAccordionDOM = () => {
    const accordionSliders = $( '.bs-advance-accordion--common-accordion-slider' );
    if ( accordionSliders.length > 0 ) {
      accordionSliders.each( ( i, el ) => {
        const cards = $( el ).find( '.card' );

        cards.each( ( i, card ) => {
          const cardHeader = $( card ).find( '.card-header' );
          const cardContent = $( card ).find( '.card__content' );

          $( card ).removeClass( 'active' );
          $( card ).find( '.temp-header' ).remove();
          cardHeader.addClass( 'accordion__block__btn' );
          cardContent.addClass( 'collapse' ).removeClass( 'card__content' );
          cardHeader.show();
        } );
      } );
    }
  };

  const toggleSliderAndAccordion = debounce( function() {
    if ( window.matchMedia( '(max-width: 990px)' ).matches ) {
      mobileSliderDOM();
    } else {
      desktopAccordionDOM();
    }
  }, 250 );

  if ( window.matchMedia( '(max-width: 990px)' ).matches ) {
    mobileSliderDOM();
  }

  $( window ).on( 'resize', debounce( toggleSliderAndAccordion, 500 ) );

} );
