( $ => {
  $( window ).on( 'load resize', () => {
    $( '.bs-row--common-left-right-row' ).each( function ( i, el ) {
      const popupVideo = $( el ).find( '.common-video-popup' );
      const reverse = $( el ).hasClass( 'flex-lg-row-reverse' );

      if ( popupVideo.length !== 0 ) {
        if ( reverse && popupVideo.offset().left < 0 ) {
          popupVideo.find( 'i' ).css( { left: `${-popupVideo.offset().left}px` } );
        } else if ( !reverse && popupVideo.offset().left > 0 ) {
          const offserRight = popupVideo.offset().left + popupVideo.width() - $( window ).width();
          popupVideo.find( 'i' ).css( { right: `${offserRight > 0 ? offserRight : 0}px` } );
        } else {
          popupVideo.find( 'i' ).css( { right: 0, left: 0 } );
        }
      }
    } );
  } );
} )( jQuery );
