( ( $ ) => {
  /* eslint-disable no-undef */
  $( document ).on( 'keyup', '.facetwp-type-search .facetwp-search', ( e ) => {
    const inputValue = e.currentTarget.value;
    if ( inputValue.length > 0 && inputValue.length < 2 ) {
      FWP.is_refresh = true;
      setTimeout( () => {
        FWP.is_refresh = false;
      }, 150 );
    }
  } );
  /* eslint-enable */
} )( jQuery );

( function ( $ ) {
  
  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-fselect .fs-options' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( '.fs-option' ).length === 0 ) {
          element.closest( '.bs-column' ).hide();
        } else {
          element.closest( '.bs-column' ).show();
        }
      } );
    }
  };

  function cleanFacetResult() {
    const searchSelections = $( '.facetwp-selections li' );
    searchSelections.each( function( i, el ) {
      const itemType = $( el ).attr( 'data-facet' );
      const itemValue = $( el ).find( '.facetwp-selection-value' ).text();

      if ( itemType == 'search' && itemValue.trim() == '' ) {
        $( '.facetwp-type-reset' ).hide();
        $( el ).hide();
      } else {
        $( '.facetwp-type-reset' ).show();
      }
    } );
  }

  $( document ).on( 'facetwp-loaded', () => {
    setTimeout( () => {
      cleanFacetResult();
    }, 0 );
    hideFiltersWhenEmpty();
  } );

  $( window ).on( 'load resize', () => {
    hideFiltersWhenEmpty();
  } );

  cleanFacetResult();
  $( document ).ready( () => {
    hideFiltersWhenEmpty();
  } );
} )( jQuery );
