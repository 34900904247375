( function ( $ ) {
  //on page load hide the reset button
  if ( $( '.facetwp-selection-value' ).length <= 0 ) {
    $( '.reset-selection' ).hide();
  }

  $( document ).on( 'facetwp-loaded', () => {
    /* eslint-disable no-undef */
    let queryString = FWP.buildQueryString();
    /* eslint-enable no-undef */
    if ( '' === queryString ) {
      // no facets are selected
      $( '.reset-selection' ).hide();
    } else {
      $( '.reset-selection' ).show();
    }
  } );

  $( document ).on( 'click', '.facetwp-page', ( e ) => {
    const adminBarHeight =
      $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
    const headerHeight =
      $( 'header' ).height() + adminBarHeight;
    const targetSectionTop = $( e.currentTarget ).closest( 'section' ).offset().top;
    $( 'html, body' ).animate(
      { scrollTop: targetSectionTop - headerHeight },
      500
    );
  } );

} )( jQuery );
