$( ( $ ) => {
  const toolTip = $( '.tool-tip' );
  if ( toolTip.length ) {
    toolTip.each( ( i, item ) => {
      const toolTipIcon = $( item ).find( '.icon' );
      const popup = $( item ).find( '.popup' );
      toolTipIcon.on( 'click', e => {
        e.preventDefault();
        if ( !popup.hasClass( 'opened' ) ) {
          $( '.popup, .icon' ).removeClass( 'opened' );
          $( toolTipIcon ).addClass( 'opened' );
          $( popup ).addClass( 'opened' );
        } else {
          $( toolTipIcon ).removeClass( 'opened' );
          $( popup ).removeClass( 'opened' );
        }
      } );
    } );
    // Close the tooltip when clicking outside of it
    $( document ).click( event => {
      if ( !toolTip.is( event.target ) && toolTip.has( event.target ).length === 0 ) {
        toolTip.find( '.icon, .popup' ).removeClass( 'opened' );
      }
    } );
  }
  
} );