$( $ => {
  const mediaQuery = window.matchMedia( '(max-width: 991px)' );
  const accordion = $( '.bs-advance-accordion--common-slider-enable-for-mobile' );
  const slider = $( accordion ).find( '.bs-advance-accordion__right-container > div' );
  
  const sliderInit = ( ) => {
    $( slider ).slick( {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: false,
      dots: true,
    } );
  };
  const sliderHandler = () => {
    // eslint-disable-next-line max-len
    const initializedSlider = $( '.bs-advance-accordion--common-slider-enable-for-mobile .slick-slider.slick-initialized' );
    if( initializedSlider.length ) {
      if ( !mediaQuery.matches ) {
        $( initializedSlider ).slick( 'unslick' );
      }
    } else {
      if ( mediaQuery.matches ) {
        sliderInit();
      }
    }
  };
  if ( mediaQuery.matches ) {
    if( accordion.length ) {
      sliderInit();
    }
  }

  $( window ).on( 'resize', sliderHandler );

} );
