( $ => {
  const lineSeparatorSections = $( '.bs-section--home-free-yourself' );
  if ( lineSeparatorSections ) {
    const items = lineSeparatorSections.find( '.bs-column > .bs-div' ),
      itemsPerRow = 3,
      numberOfItemsInLastRow = items.length % itemsPerRow || itemsPerRow;

    if ( numberOfItemsInLastRow == 2 ) {
      lineSeparatorSections.addClass( 'last-row-two-items' );
    } else if ( numberOfItemsInLastRow == 1 ) {
      lineSeparatorSections.addClass( 'last-row-one-item' );
    }
  }
} )( jQuery );
