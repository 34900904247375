$( ( $ ) => {
  const mediaQuery = window.matchMedia( '(min-width: 991px)' );
  const wrapper = $( '.bs-section--award-and-recognition .bs-slider--about-awards' );
  const slickSlider = $( wrapper ).find( '.slick-slider' );
  let wrapperWidth = null;
  let slickTrackWidth = null;
  let arrowsHidden = false;
  
  const sliderHandler = () => {
    if ( mediaQuery.matches && slickSlider.length ) {
      $( slickSlider ).on( 'init beforeChange', function() {
        wrapperWidth = $( wrapper ).width();
        slickTrackWidth = $( slickSlider ).find( '.slick-track' ).width();
        getSliderState( wrapperWidth, slickTrackWidth );
      } );
    }
  };

  const getSliderState = ( wrapWidth, trackWidth ) => {
    setTimeout( function() {
      if( ( wrapWidth + 20 ) > trackWidth ) {
        if ( !arrowsHidden ) {
          $( slickSlider ).addClass( 'arrows-hidden' );
          $( slickSlider ).slick( 'slickSetOption', 'swipe', false );
          arrowsHidden = true;
        }
      } else {
        $( slickSlider ).removeClass( 'arrows-hidden' );
        $( slickSlider ).slick( 'slickSetOption', 'swipe', true );
        arrowsHidden = false;
      }
    }, 600 );
    
  };

  if ( mediaQuery.matches ) {
    sliderHandler();
  }

  slickSlider.slick();
  
  $( window ).on( 'resize', sliderHandler );
  
} );