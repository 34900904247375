import { debounce } from 'lodash';
import { headerScrollUp, isDesktop, headerTransition } from '../custom/header-sub-funtions';
( $ => {
  const header = $( '#header' );
  const topMenuItems = $( '#menu-top-menu > .menu-item > a' );
  const topSubMenuItems = $( '#menu-top-menu > .menu-item > .sub-menu' );
  const mainMenuItems = $( '#mega-menu-main-menu > .mega-menu-item > a' );
  const mainSubMenuItems = $( '#mega-menu-main-menu > .mega-menu-item > .mega-sub-menu' );
  const mobileMenuToggle = $( '#mega-menu-wrap-main-menu > .mega-menu-toggle' );
  const notifyBar = $( '#notify-bar .notify-bar__inner' );
  let submenuVisible = false;
  let preScrollPos,
    currentScrollPos,
    transformY = 0;
  const debounceHandler = () => {
    if ( isDesktop() ) {
      clearHeaderClass();
      toggleHeader();
    }
  };
  const toggleScrolledClass = () => {
    currentScrollPos = $( window ).scrollTop();
    headerTransition( currentScrollPos, header, notifyBar, transformY );
    headerScrollUp( currentScrollPos, preScrollPos, header );
    preScrollPos = currentScrollPos;
  };
  const toggleClass = currentParent => {
    if ( currentParent.hasClass( 'menu-item-has-children' ) ) {
      currentParent.toggleClass( 'opened-sub-menu' );
    }
  };
  const headerToggleClass = () => {
    header.toggleClass( 'opened' );
  };
  const headerAddClass = () => {
    header.addClass( 'opened' );
  };
  const headerRemoveClass = () => {
    header.removeClass( 'opened' );
  };
  const toggleTopMenu = () => {
    if ( !topMenuItems.length ) {
      return false;
    }
    topMenuItems
      .on( 'mouseenter', e => {
        toggleClass( $( e.target ).closest( '.menu-item' ) );
      } )
      .on( 'mouseleave', e => {
        toggleClass( $( e.target ).closest( '.menu-item' ) );
      } );
    topSubMenuItems
      .on( 'mouseenter', e => {
        toggleClass( $( e.target ).closest( '.menu-item' ) );
        $( e.target ).click();
      } )
      .on( 'mouseleave', e => {
        toggleClass( $( e.target ).closest( '.menu-item' ) );
      } );
  };
  const toggleHeader = () => {
    if ( !mainMenuItems.length ) {
      return false;
    }
    mainMenuItems
      .on( 'mouseenter', () => {
        clearTimeout( submenuVisible );
        setTimeout( () => {
          headerAddClass();
        }, 150 );
      } )
      .on( 'mouseleave', () => {
        submenuVisible = setTimeout( () => {
          headerRemoveClass();
        }, 250 );
      } );
    mainSubMenuItems
      .on( 'mouseenter', () => {
        clearTimeout( submenuVisible );
        headerAddClass();
      } )
      .on( 'mouseleave', () => {
        submenuVisible = setTimeout( () => {
          headerRemoveClass();
        }, 250 );
      } );
  };
  const hamburgerMenuClick = () => {
    mobileMenuToggle.on( 'click', () => {
      headerToggleClass();
    } );
  };
  const clearHeaderClass = () => {
    header.removeClass( 'opened' );
  };
  toggleTopMenu();
  debounceHandler();
  toggleScrolledClass();
  hamburgerMenuClick();
  
  $( '.sub-menu li' ).on( 'click', 'a', ( e ) => {
    let subMenuUrl = $( e.target ).attr( 'href' );
    window.location.href = subMenuUrl; 
  } );


  $( window ).on( 'scroll', () => {
    toggleScrolledClass();
  } );
  // eslint-disable-next-line no-undef
  $( window ).on( 'resize', debounce( debounceHandler, 500 ) );
} )( jQuery );