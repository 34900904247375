/* global debounce */
class VerticalTab {
  constructor( tabWrapper ) {
    this.tabWrapper = tabWrapper;
    this.linkWrapper = this.tabWrapper.find( '.vertical-tab__link-wrapper' );
    this.links = this.linkWrapper.find( '.vertical-tab__link' );
    this.activeLinkPointer = this.linkWrapper.find( '.vertical-tab__link-pointer' );
    this.contents = this.tabWrapper.find( '.vertical-tab__content' );
    this.topPosition = 0;
    this.topContent = this.tabWrapper.find( '.vertical-tab__top' );
    this.linkContentWrapper = this.tabWrapper.find( '.vertical-tab__links' );
    this.windowHeight = $( window ).height();
    this.windowWidth = $( window ).width();
    this.headerHeight = $( 'header' ).height();
    this.fourKHeight = 1080;
    this.tempTop = 0;
    this.isSetTop = false;
  }

  updateActiveLinkPointer() {
    const activeLink = this.links.filter( '.active' );
    this.activeLinkPointer.css( {
      height: activeLink.outerHeight(),
      top: activeLink.position().top,
    } );
  }

  setContentEqualHeight() {
    let maxHeight = 0;
    this.contents.css( { height: 'auto' } );
    this.contents.each( ( i, content ) => {
      const thisHeight = $( content ).outerHeight( true );
      if ( thisHeight > maxHeight ) {
        maxHeight = thisHeight;
      }
    } );
    this.contents.css( {
      height: maxHeight + 30,
    } );
    this.linkContentWrapper.css( {
      'min-height': maxHeight + 80,
    } );
  }

  getContentTop() {
    this.contents.each( ( i, content ) => {
      const thisTop = $( content ).offset().top;
      $( content ).attr( 'data-off-top', thisTop );
    } );
  }

  activeTab( index ) {
    const selectedContent = this.contents.eq( index );
    const selectedPredecessor = this.contents.eq( index - 1 );
    const selectedNextContent = this.contents.eq( index + 1 );
    const selectedLink = this.links.eq( index );
    this.contents.not( selectedContent ).removeClass( 'active' );
    this.contents.not( selectedPredecessor ).removeClass( 'active-predecessor' );
    this.contents.not( selectedNextContent ).removeClass( 'next-active' );
    this.links.not( selectedLink ).removeClass( 'active' );
    selectedContent.addClass( 'active' );
    selectedLink.addClass( 'active' );
    if ( index !== 0 ) {
      selectedPredecessor.addClass( 'active-predecessor' );
    }
    if ( index !== this.contents.length - 1 ) {
      selectedNextContent.addClass( 'next-active' );
    }
    this.updateActiveLinkPointer();
    this.tabWrapper.parents( 'section' ).toggleClass( 'last-content-active', this.contents.length == index+1 );
  }

  resizeHandler() {
    this.setContentEqualHeight();
    this.checkSectionHeight();
  }

  scrollTo( index ) {
    let pos = this.contents.eq( index ).attr( 'data-off-top' ) - ( this.topPosition + 100 );
    $( 'html,body' ).animate(
      {
        scrollTop: pos,
      },
      750
    );
  }

  setTopPosition( top ) {
    this.topPosition = top;
  }

  checkSectionHeight () {
    const sectionPadding = parseInt( this.tabWrapper.parents( 'section' ).css( 'padding-top' ) );
    const contentHeight = this.topContent.outerHeight( true );
    if( this.windowHeight > this.fourKHeight ) {
      this.tabWrapper.parents( 'section' ).addClass( 'less-height' );
      this.topContent.css( {'top': `${this.headerHeight + sectionPadding}px`} );
      this.linkContentWrapper.css( {'top': `${this.headerHeight + sectionPadding + contentHeight}px`} );
      this.contents.css( {'top': `${this.headerHeight + sectionPadding + contentHeight + 50 }px`} );
      this.contents.eq( 0 ).css( {'top': `${this.headerHeight + sectionPadding + contentHeight}px`} );
      const topPosition = this.headerHeight + sectionPadding + contentHeight;
      this.setTopPosition( topPosition );
    }
    else {
      this.setTopPosition( this.headerHeight + sectionPadding );
    }
  }

  checkLastContentActive ( top ) {
    const sectionPadding = parseInt( this.tabWrapper.parents( 'section' ).css( 'padding-top' ) );
    const contentHeight = this.topContent.outerHeight( true );
    const windowScrollTop = $( window ).scrollTop();
    if ( top <= this.headerHeight + sectionPadding + contentHeight + 50 ) {
      if ( !this.isSetTop ) {
        this.tempTop = windowScrollTop;
        this.isSetTop = true;
      }
      const movingValue = windowScrollTop - this.tempTop;
      this.topContent.css( {
        'transform': `translate3d(0, -${movingValue}px, 0)`
      } );
    } else{
      this.topContent.css( {
        'transform': 'translate3d(0, 0, 0)'
      } );
    }
		
  }

  init() {
    this.setContentEqualHeight();
    this.getContentTop();
    this.checkSectionHeight();
    this.activeTab( 0 );
    this.tabWrapper.parents( 'section' )
      .addClass( 'animation-init' )
      .css( 'min-height', this.tabWrapper.parents( 'section' ).outerHeight() );
    this.links.on( 'click', e => {
      const link = $( e.target );
      link.addClass( 'active' );
      this.links.not( link ).removeClass( 'active' );
      this.updateActiveLinkPointer();
      const pos = link.index() - 1;
      this.scrollTo( pos );
    } );
    
    $( window )
      .on(
        'resize',
        debounce( () => {
          this.resizeHandler();
        }, 30 )
      )
      .on( 'scroll', () => {
        this.contents.each( ( i, content ) => {
          const top = content.getBoundingClientRect().y;
          let changeActivePos = this.contents.eq( i - 1 ).find( '.vertical-tab__content-inner-box' ).outerHeight();
          if ( this.windowHeight > this.fourKHeight ) {
            changeActivePos = changeActivePos / 3;
          }
          if ( this.windowWidth < 992 ) {
            changeActivePos = changeActivePos / 16;
          }
          if ( top < this.topPosition + changeActivePos + 100 ) {
            this.activeTab( i );
          }
          if ( i == this.contents.length - 1 ) {
            this.checkLastContentActive( top );
          }
        } );
      } ).trigger( 'scroll' );
  }
}

$( $ => {
  if( $( '.bs-section--home-vertical-tab ' ).length === 0 ) return;
  $( window ).scrollTop( 0 );
  $( '.vertical-tab' ).each( ( i, tabWrapper ) => {
    const verticalTab = new VerticalTab( $( tabWrapper ) );
    verticalTab.init();
  } );
} );
