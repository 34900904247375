$( $ => {
  const blogProgress = $( '.bs-section--common-scroll-progress' );
  const progressFunc = () => {
    const windowScroll = $( window ).scrollTop(),
      windowHeight = $( window ).height(),
      scrollHeight = $( '.progress-content-wrapper' ).height(),
      scrolledPer = ( windowScroll / ( scrollHeight - windowHeight ) ) * 100;
    $( '.blog-inner-progress__bar' ).css( 'width',`${scrolledPer}%` );
  };
  if( blogProgress.length !== 0 ) {
    const preogressBarEl = '<div class="blog-inner-progress"><span class="blog-inner-progress__bar"></span></div>';
    blogProgress.prepend( preogressBarEl );
    $( 'section' ).slice( 0,3 ).wrapAll( '<div class="progress-content-wrapper"></div>' );
    $( window ).on( 'scroll', progressFunc );
    $( window ).scroll();
  }
} );