$( () => {
  const partnersBanner = $( '.bs-section--partners-banner' );
  const multipleMedia = $( '.bs-section--partners-banner .bs-div--multiple-media' );

  const getRandomDiv = () => {
    const randomIndex = Math.floor( Math.random() * multipleMedia.length );
    return multipleMedia.eq( randomIndex );
  };

  const toggleClassWithDelay = () => {
    const randomDiv = getRandomDiv();
    randomDiv.toggleClass( 'selected' );
  };

  $( window ).on( 'load', () => {
    if ( partnersBanner.length > 0 ) {
      partnersBanner.addClass( 'section-loaded' );
      setInterval( toggleClassWithDelay, 4000 );
    }
  } );
} );