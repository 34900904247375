$( ( $ ) => {
  const winHeight = $( window ).height();
  const pricingHeader = $( '.bs-section--pricing-features-table .table-wrapper' );
  let rootMarginForObserver;

  if ( winHeight < 1500 ) {
    rootMarginForObserver = '0px 0px -75% 0px';
  } else if ( winHeight < 900 ) {
    rootMarginForObserver = '0px 0px -62% 0px';
  } else {
    rootMarginForObserver = '0px 0px -85% 0px';
  }

  const observerOptions = {
    root: null,
    rootMargin: rootMarginForObserver,
    threshold: 0
  };
  

  const setPricingHeaderSticky = entries => {
    entries.forEach( entry => {
      if ( entry.isIntersecting || entry.boundingClientRect.top < 0 ) {
        $( pricingHeader ).addClass( 'header-pinned' );
        $( pricingHeader ).addClass( 'header-pinned-added' );
        $( 'body' ).addClass( 'body--has-pricing-table-sticky' );
        $( pricingHeader ).removeClass( 'header-pinned-removed' );
      } else {
        $( pricingHeader ).removeClass( 'header-pinned' );
        $( pricingHeader ).addClass( 'header-pinned-removed' );
        $( pricingHeader ).removeClass( 'header-pinned-added' );
        $( 'body' ).removeClass( 'body--has-pricing-table-sticky' );
      }
    } );
  };

  const observer = new IntersectionObserver( setPricingHeaderSticky, observerOptions );
  if ( pricingHeader.length ) {
    observer.observe( pricingHeader[0] );
  }

} );