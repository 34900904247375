// /* eslint-env jquery */
( $ => {
  const accordionBtn = $( '.bs-section--privacy-content .accordion__block__btn' );
  accordionBtn.on( 'click', e => {
    const $this = $( e.target );
    if ( !$this.parents().hasClass( 'bs-advance-accordion--common-no-scroll-animation' ) ) {
      setTimeout( function () {
        let topPosition = $( $this ).offset().top - 120;
        $( 'html,body' ).animate(
          {
            scrollTop: topPosition,
          },
          'slow'
        );
      }, 1000 );
    }
  } );
} )( jQuery );
