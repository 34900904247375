( $ => {
  const section = $( '.bs-section--about-learn-more' );
  if( section.length > 0 ){
    let container = $( '.bs-section--about-learn-more .bs-column:last-child' );
    let selectedElement = container.find( '.bs-div--learn-more-card:nth-child(3n+4)' ).clone();
    selectedElement.addClass( 'hide' );
    container.append( selectedElement );
  }
  
  const section1 = '.bs-section--about-learn-more .bs-div--learn-more-card';
  const section2 = '.bs-section--trust-center-content-block .bs-div--trust-center-content-block';
  const section3 = '.bs-section--customers-learn-more .bs-div--customers-learn-more ';
  const learnMoreCards = $( section1+','+section2+','+section3 );
  learnMoreCards.on( 'click', function(){
    $( this ).find( 'a' )[0].click();
  } );

} )( jQuery );