$( () => {
  const mediaQuery = window.matchMedia( '(min-width: 992px)' );
  const accordion = $( '.bs-advance-accordion--common-auto-play' );
  const tabs = accordion.find( '.card' );
  const sliderDuration = $( '.bs-div--common-accordion-slider-duration p' );
  let initialDelay = 100; // Ensure a default value
  let currentIndex = 0; // Initialize index to 0
  let timeoutId;
  let isAnimation = false;
  let isInitialItem = true;

  $( window ).scroll( () => {
    if ( $( document ).scrollTop() > 200 ) {
      isInitialItem = false;
    }
  } );

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2
  };

  const createProgressAnimator = ( container ) => {
    const animator = $( container ).find( '.card-header__animator' );
    const duration = parseInt( $( container ).find( sliderDuration ).text() ) || 3000;
    
    if ( animator.length < 1 ) {
      const div = $( '<div>' ).addClass( 'card-header__animator' ).css( 'animation-duration', duration + 'ms' );
      $( container ).find( '.card-header' ).prepend( div );
    }
  };

  tabs.each( ( index, element ) => {
    if ( mediaQuery.matches ) {
      $( element ).removeClass( 'active' );
      createProgressAnimator( element );
      $( element ).parent().attr( 'data-length', tabs.length );
      $( element ).attr( 'data-index', index );

      const showingItem = $( element ).find( '.show' );
      if ( showingItem.length > 0 ) {
        $( element ).addClass( 'active' );
      }
    }
  } );
  

  tabs.find( '.accordion__block__btn' ).on( 'click', function( event ) {
    if ( event.originalEvent && mediaQuery.matches ) {
      $( this ).parent().addClass( 'active' );
      const tabsLength = $( this ).closest( '.card' ).parent().data( 'length' );
      let clickedIndex = ( $( this ).closest( '.card' ).data( 'index' ) + 1 );
      if( tabsLength === clickedIndex ) {
        clickedIndex = 0;
      }
      const clickedDelay = parseInt( $( this ).parent().before().find( sliderDuration ).text() ) || 3000;
      stopAutoPlay();
      initialDelay = clickedDelay;
      startAutoPlay( clickedIndex );
    }
  } );

  const clickTab = () => {
    if ( isAnimation && mediaQuery.matches ) {
      const tab = tabs.eq( currentIndex );
      const interval = parseInt( tab.find( sliderDuration ).text() ) || 3000;
      if ( !isInitialItem ) {
        tab.find( '.accordion__block__btn' ).trigger( 'click' );
      }
      currentIndex = ( currentIndex + 1 ) % tabs.length;
      timeoutId = setTimeout( clickTab, interval );
      isInitialItem = false;
    }
  };

  const startAutoPlay = ( latestIndex ) => {
    currentIndex = latestIndex;
    if ( !isAnimation ) {
      timeoutId = setTimeout( clickTab, initialDelay );
      isAnimation = true;
    }
  };

  const stopAutoPlay = ( notIntersecting = false ) => {
    if ( isAnimation ) {
      if( notIntersecting ) {
        const activeIndex = tabs.filter( ( i, tab ) => $( tab ).hasClass( 'active' ) );
        activeIndex.find( '.accordion__block__btn' ).trigger( 'click' );
      }
      clearTimeout( timeoutId );
      initialDelay = 0;
      isAnimation = false;
    }
  };

  const handleResize = () => {
    if ( mediaQuery.matches ) {
      startAutoPlay( 0 );
    } else {
      stopAutoPlay();
    }
  };

  const observer = new IntersectionObserver( ( entries ) => {
    entries.forEach( ( entry ) => {
      entry.isIntersecting ? startAutoPlay( 0 ) : stopAutoPlay( true );
    } );
  }, observerOptions );

  if ( accordion.length ) {
    observer.observe( accordion[0] );
  }

  handleResize(); // Initial check

  $( window ) .on( 'resize', handleResize );
} );